import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Subscribe from '../components/Subscribe/index.jsx'
import Footer from '../components/Footer'
import Rating from '../components/Rating'

import '../js/script.js';

export default class IndexPage extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.lastEntry
    const { edges: previousEntries } = data.previousEntries

    return (
      <Layout>
      
        <section className="latest-tip">
          <div className="container">
            <div className="content todays-tip">
              <h1>Tip van vandaag</h1>
            </div>
            {posts
              .map(({ node: post }) => (
                <div
                  className="tip"
                  key={post.id}
                >
                  <h1>
                    <Link to={post.fields.slug}>
                      {post.frontmatter.title}
                    </Link>
                  </h1>
                  <small>{post.frontmatter.date}</small>
                  <div className="tip-container">
                    <div className="tip-text" dangerouslySetInnerHTML={{ __html: post.html }}>
                    </div>
                  </div>
                </div>
              ))}
              <Rating />
          </div>
        </section>
        <Subscribe />
        <section className="prev-tips">
        <div className="container">
        {previousEntries
          .map(({ node: post }) => (
            <div
              className="prev-tip"
              key={post.id}
            >
              <h2>
                <Link  to={post.fields.slug}>
                  {post.frontmatter.title}
                </Link>
              </h2>
              <p>
                {post.excerpt}
              </p>
              <p>
                <Link className="button is-small" to={post.fields.slug}>
                  Verder lezen →
                </Link>
              </p>
            </div>
        ))}
        </div>
        </section>
        <section className="bonustip">
          <div className="container">
            <h3><span>Bonustip</span> Performance performance performance</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed diam orci, ultrices eget augue sed, molestie interdum odio. Mauris nec convallis massa.</p>
          </div>
        </section>
        <Footer />
      </Layout>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const pageQuery = graphql`
  query IndexQuery {
    lastEntry:allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] },
      filter: { frontmatter: { templateKey: { eq: "blog-post" } }},
      limit: 1
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          html
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "DD MMMM YYYY")
          }
        }
      }
    }
    previousEntries:allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] },
      filter: { frontmatter: { templateKey: { eq: "blog-post" } }},
      limit: 25
      skip: 1
    ) {
      edges {
        node {
          excerpt(pruneLength: 200)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "DD MMMM YYYY")
          }
        }
      }
    }
  }
`
