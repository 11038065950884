//import stuff
    import React, { Component } from 'react';
    import schoentje from '../img/schoentje.svg'
    import wortel from '../img/wortel.svg'

    //the class you are making your component from
    class AboutModal extends Component {
      // constructor to set state and bind "this"
      constructor(props) {
          super(props);
          this.state = {showModal: false};
          this.handleClick = this.handleClick.bind(this);
        }

      // function to handle the click
       handleClick(data, e) {
        //this.getElementById('icon').add("geworteld");
        data.target.parentElement.classList.add("geworteld");
      }

      // the render() method to put stuff into the DOM
      render() {
        // the modal you will toggle on and off

        // the return() to put your default HTML into the DOM
        return (
              <div className="rating">
                <div className="hint">
                  Leuke tip? Stop een wortel in de schoen. &raquo;
                </div>
                <div className="icon" id="icon" onClick={this.handleClick}>
                  <img src={schoentje} alt="Schoentje" className="schoentje" style={{ width: '100%' }} />
                  <img src={wortel} alt="Wortel" className="wortel" />
                </div>
            </div>
        );
      }
    }
    // export the class so you can call it elsewhere
    export default AboutModal;
